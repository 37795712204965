import React from "react";

import { Container } from "../Common/Grid";
import MutedText from "../Common/MutedText";

function ArticleComponent(props) {
  return (
    <Container>
      <article>
        <header>
          <h1 style={{ marginBottom: 0 }}>{props.title}</h1>
          <MutedText size="small">{props.publishedAt}</MutedText>
        </header>
        <div style={{ height: "0.75rem" }} />

        <main dangerouslySetInnerHTML={{ __html: props.html }} />
        <br />
      </article>
    </Container>
  );
}

export default ArticleComponent;

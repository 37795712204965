export default {
  colors: {
    primary: "#1D2232",
    accent: "#E37D43",

    text__body: "#1D2232",
    text__muted: "#6b6f74",
    text__accent: "#E37D43",
  },
};

import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import Article from "../components/Article";

function PostTemplate(props) {
  return (
    <Layout>
      <SEO title="" />
      <Article {...props.data} />
    </Layout>
  );
}

function PostTemplateContainer({ data, ...props }) {
  return (
    <PostTemplate data={parseDataToOneLevel(data.markdownRemark)} {...props} />
  );
}

export default PostTemplateContainer;

function parseDataToOneLevel(data) {
  const { frontmatter, fields, ...rest } = data;
  return {
    ...fields,
    ...frontmatter,
    ...rest,
  };
}

export const PostPageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      fields {
        slug
      }
      frontmatter {
        title
        publishedAt(formatString: "MMM DD, YYYY", locale: "en")
      }
      timeToRead
      tableOfContents(maxDepth: 2)
      html
    }
  }
`;

import React from "react";

import Header from "../Header";

import GlobalStyles from "./GlobalStyles";
import "../../lib/theme/reset.css";

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Header />
      {children}
    </>
  );
}

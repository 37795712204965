import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Container } from "../Common/Grid";

const Header = styled.header`
  width: 100%;
  padding: 1rem 0;
`;

const HeaderLogo = styled(Link)`
  font-size: 1.2rem;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  transition: color 75ms ease-in;

  &:hover {
    color: ${({ theme }) => theme.colors["accent"]};
  }
`;

export default function HeaderComponent() {
  return (
    <Header>
      <Container>
        <HeaderLogo to="/">AARGRK</HeaderLogo>
      </Container>
    </Header>
  );
}

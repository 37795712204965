import React from "react";
import styled from "styled-components";
import { Container } from "../Common/Grid";
import BlogPostThumbnail from "./BlogPostThumbnail";

export default function BlogPostListComponent({ data }) {
  function renderPostThumbnail({ node }) {
    return (
      <BlogPostThumbnail
        key={node.id}
        {...Object.assign({}, node.fields, node.frontmatter)}
      />
    );
  }

  return <StyledContainer>{data.map(renderPostThumbnail)}</StyledContainer>;
}

const StyledContainer = styled(Container)`
  display: grid;
  grid-gap: 30px;
`;

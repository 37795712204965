import styled from "styled-components";

const MutedText = styled.span`
  color: ${({ theme }) => theme.colors["text__muted"]};
  font-size: ${({ size }) => parseFontSize(size)};
`;

MutedText.defaultProps = {
  size: "normal",
};

export default MutedText;

function parseFontSize(size) {
  switch (size) {
    case "small":
      return "0.8rem";
    default:
      return "1rem";
  }
}

import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import MutedText from "../Common/MutedText";

function BlogPostThumbnailComponent(props) {
  return (
    <PostThumbnail>
      <PostThumbnailTitle>{props.title}</PostThumbnailTitle>
      <MutedText size="small">{props.publishedAt}</MutedText>
      <NavigationOverlay
        to={props.slug}
        aria-label={`Read ${props.title} post`}
      />
    </PostThumbnail>
  );
}

export default BlogPostThumbnailComponent;

const PostThumbnail = styled.div`
  position: relative;
  padding: 0.5rem 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
`;

const NavigationOverlay = styled(Link)`
  position: absolute;
  content: " ";
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  will-change: border-color;
  transition: border-color 250ms;
  border-left: 4px solid;
  border-color: transparent;
  color: transparent;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors["accent"]};
  }
`;

const PostThumbnailTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
`;

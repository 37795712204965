import React from "react";
import { graphql } from "gatsby";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import BlogPostList from "../components/BlogPostList";

function IndexPageContainer({ data }) {
  return <IndexPageTemplate posts={data.allMarkdownRemark.edges} />;
}

function IndexPageTemplate(props) {
  return (
    <Layout>
      <SEO title="" />
      <Spacer />
      {!isEmpty(props.posts) && <BlogPostList data={props.posts} />}
    </Layout>
  );
}

const Spacer = styled.div`
  width: 100%;
  height: 4.25rem;
`;

export default IndexPageContainer;

export const IndexPageQuery = graphql`
  query IndexPageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { publish: { eq: true } } }
      sort: { fields: frontmatter___publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            publishedAt(formatString: "MMM DD, YYYY", locale: "en")
          }
        }
      }
    }
  }
`;

import styled from "styled-components";
import media from "../../../lib/theme/media";

export default styled.div`
  max-width: 100%;
  padding: 0 21px;
  margin: 0 auto;

  @media ${media.small} {
    padding: 0 21px;
  }

  @media ${media.medium} {
    width: 90vw;
  }

  @media ${media.large} {
    width: 80vw;
  }

  @media ${media.xLarge} {
    width: 100%;
    max-width: 46rem;
  }
`;

import { createGlobalStyle } from "styled-components";
import theme from "../../lib/theme";

const GlobalStyle = createGlobalStyle`
html {
    font-size: 125%;
  }
  body {
    color: ${theme.colors["text__body"]};
    font-family: sans-serif;
    line-height: 1.39;
  }
  a {
    color: orange;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.25em;
    margin-bottom: 0.5em;
  }

  h1 {
    font-size: 2rem;
    line-height: 1.34;
  }

  article {
    line-height: 1.49;
    p, ul, ol {
      margin-bottom: 1.5rem;
    }
    li > p {
      margin-bottom: 0.5rem;
    }
  }

`;

export default GlobalStyle;
